import { TitleDescriptionLinkType } from '../../types';
import { buttonVariants } from '../Button';
import NoPrefetchLink from '../NoPrefetchLink';

interface CallToActionCardProps {
  value: TitleDescriptionLinkType;
}

function CallToActionCard({ value }: CallToActionCardProps) {
  const { title, description, link, linkSecondary, isWide } = value;

  return (
    <div
      className={`bg-primary text-on-primary ${
        isWide ? 'lg:-mx-32' : 'lg:-mx-19'
      } my-8 flex items-center justify-center rounded-md px-8 py-10 md:my-14 md:py-14`}
    >
      <div className="max-w-[75ch] text-center">
        <h2
          className="mb-3 text-2xl font-semibold sm:mb-5"
          style={{
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            textWrap: 'balance',
          }}
        >
          {title}
        </h2>
        <p className="mb-8 text-lg sm:mb-10">{description}</p>
        <div className="flex flex-col items-center justify-center gap-3 sm:flex-row sm:gap-5">
          {link?.linkText && (
            <NoPrefetchLink
              className={buttonVariants({ variant: 'primary' })}
              href={link.href || '/'}
            >
              {link.linkText}
            </NoPrefetchLink>
          )}
          {linkSecondary?.linkText && (
            <NoPrefetchLink
              className={buttonVariants({ variant: link?.linkText ? 'secondary-dark' : 'primary' })}
              href={linkSecondary.href || '/'}
            >
              {linkSecondary.linkText}
            </NoPrefetchLink>
          )}
        </div>
      </div>
    </div>
  );
}

export default CallToActionCard;
