import { PortableTextBlock } from '@portabletext/types';
/* eslint-disable import/no-cycle */
import PortableText from './PortableText';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from './Accordion';

export interface FaqType {
  title: string;
  questions: {
    question: string;
    answer: PortableTextBlock[];
  }[];
}
export const Faq = ({ title, questions }: FaqType) => {
  return (
    <div className="mx-auto my-10  lg:my-20">
      <h2 className="mb-5 text-3xl font-semibold lg:text-4xl">{title}</h2>
      <Accordion type="single" collapsible>
        {Array.isArray(questions) &&
          questions.map((q) => (
            <div key={q.question} className="mx-auto my-5  lg:my-5 ">
              <AccordionItem value={q.question}>
                <AccordionTrigger>{q.question}</AccordionTrigger>
                <AccordionContent>
                  <div className="mx-auto my-5  lg:my-5">
                    <PortableText value={q.answer} />
                  </div>
                </AccordionContent>
              </AccordionItem>
            </div>
          ))}
      </Accordion>
    </div>
  );
};

export default Faq;
