/* eslint-disable react/jsx-props-no-spreading */
import { motion, useAnimationControls } from 'framer-motion';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useDraggable } from 'react-use-draggable-scroll';
import useMediaQuery from '../../utils/useMediaQuery';
import { TableType } from '../../types';
import ScrollHint from './ScrollHint';
import TableCaption from './TableCaption';
import TableRow from './TableRow';
import styles from './index.module.scss';

interface TableProps {
  tableWithTitle: {
    isWide: boolean;
    title: { isVisible?: boolean; title: string };
    description?: { isVisible?: boolean; description?: string };
    table: TableType;
  };
}

function Table({ tableWithTitle }: TableProps) {
  const { table, title, description, isWide } = tableWithTitle;
  const scrollHintRef = useRef<HTMLDivElement>(null);
  const controls = useAnimationControls();
  const hasCaption = title.isVisible || description?.isVisible;
  const tableHeadCells = table.rows[0].cells;
  const tableBodyRows = table.rows.slice(1);
  const tableContainerRef = useRef<HTMLDivElement>() as MutableRefObject<HTMLInputElement>;
  const { events } = useDraggable(tableContainerRef);
  const isNarrowScreen = useMediaQuery('(max-width: 767px)');
  const [showScrollHint, setShowScrollHint] = useState(true);
  const [hasHorizontalScroll, setHasHorizontalScroll] = useState(false);

  useEffect(() => {
    const checkScroll = () => {
      if (tableContainerRef.current) {
        const { scrollWidth, clientWidth } = tableContainerRef.current;
        setHasHorizontalScroll(scrollWidth > clientWidth);
      }
    };

    checkScroll();
    window.addEventListener('resize', checkScroll);
    return () => window.removeEventListener('resize', checkScroll);
  }, []);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isNarrowScreen && hasHorizontalScroll) {
      setShowScrollHint(true);
      const timer = setTimeout(() => {
        setShowScrollHint(false);
      }, 5000); // Hide scroll hint after 5 seconds on narrow screens
      return () => clearTimeout(timer);
    }
  }, [isNarrowScreen, hasHorizontalScroll]);

  return (
    <section className={`${isWide && 'lg:-mx-40'}`}>
      <TableCaption title={title} description={description} />
      <motion.div
        ref={tableContainerRef}
        onMouseDown={hasHorizontalScroll && !isNarrowScreen ? events.onMouseDown : undefined}
        className={`${styles.tableScrollbar} relative mb-8 overflow-x-auto rounded-b ${
          !hasCaption && 'rounded-t'
        } border border-slate-300`}
        onScroll={() => isNarrowScreen && setShowScrollHint(false)} // Hide hint on scroll for narrow screens
      >
        {hasHorizontalScroll && showScrollHint && isNarrowScreen && (
          <ScrollHint scrollHintRef={scrollHintRef} controls={controls} />
        )}
        <table
          aria-describedby="tableCaption"
          className={`w-full table-auto  whitespace-nowrap rounded-md text-left text-base text-gray-600 `}
        >
          <thead className="border-b border-slate-300 bg-slate-200">
            <tr>
              {tableHeadCells.map((cell: string, index: number) => {
                const isLast = index === tableHeadCells.length - 1;
                return (
                  <th
                    key={cell}
                    className={`${
                      !isLast && 'border-r'
                    } border-slate-300 p-3 font-medium text-gray-800`}
                  >
                    {cell}
                  </th>
                );
              })}
            </tr>
          </thead>

          <tbody className="bg-white/40">
            {tableBodyRows.map((row, index: number) => {
              const isLastRow = index === tableBodyRows.length - 1;
              const { cells } = row;
              return <TableRow key={row._key} cells={cells} isLastRow={isLastRow} />;
            })}
          </tbody>
        </table>
      </motion.div>
    </section>
  );
}

export default Table;
